.anw-swiper-suggestion {
  .swiper-slide {
    width: 100%;
    @include media-breakpoint-up(sm) {
      width: 40%;
    }
    @include media-breakpoint-up(md) {
      width: 32%;
    }
  }
  .swiper-pagination-bullets {
    &.swiper-pagination-horizontal {
      bottom: 0px;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      justify-content: center;
      list-style: none;
      margin: 0;
      padding: 2px 0;
      .swiper-pagination-bullet {
        display: flex !important;
        margin: 0 !important;
      }
    }
  }
}
