.swiper-button-prev-lawyer {
  left: -5px;
}

.swiper-button-next-lawyer {
  right: -5px;
}

.anw-swiper-lawyer {
  .swiper-slide {
    width: 165px;
  }
}
