@import '../../../defaults';
@import 'swiper-lawyer';
@import 'swiper-company';
@import 'swiper-suggestion';

:root {
  --swiper-theme-color: #{$gray-600} !important;
  --swiper-navigation-size: 20px;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-color: #{$gray-600} !important;
  --swiper-pagination-bullet-size: #{$font-size-base};
}

#content {
  background: url('./images/header-dark.jpg') no-repeat top center;

  @include media-breakpoint-up(xxl) {
    background-size: contain;
  }
}

#searchForms {
  .anw-inner {
    background-color: rgba($white, 0.8);
  }

  .anw-content-nav {
    background: none;
  }
}

.anw-search-form-heading {
  font-size: $anw-custom-h1-font-size;
  font-weight: $font-weight-bold;
  line-height: 1.4;

  @include media-breakpoint-up(md) {
    font-size: $anw-custom-h1-font-size-lg;
    font-weight: $font-weight-normal;
  }

  .anw-sub-heading {
    font-size: $anw-custom-h3-font-size;

    @include media-breakpoint-up(md) {
      font-size: 1.875rem;
    }
  }
}

.anw-block-heading {
  font-size: 1.25rem;
}

@include media-breakpoint-up(xl) {
  .anw-border-start-sm {
    border-left: map-get($spacers, 1) / 2 solid $gray-300;
  }
}

.anw-area-map {
  height: auto;
  max-width: 100%;

  @include media-breakpoint-up(lg) {
    max-width: 230px;
  }
}

.anw-key-figure-text {
  font-size: 1.25rem;
}

.anw-icon-width {
  width: 20px !important;
}

@include media-breakpoint-up(xl) {
  .anw-card-body {
    min-height: 95px;
  }
}
