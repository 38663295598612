.swiper-button-prev-company {
  left: -5px;
}

.swiper-button-next-company {
  right: -5px;
}

.anw-swiper-company {
  .swiper-slide {
    width: 165px;
  }
}
